'use client';
import { useEffect, useState } from 'react';
import { useAccount } from '@/lib/context/account-context';

export const useIsDemoEnv = () => {
  const [isDemoEnv, setIsDemoEnv] = useState(false);
  const { ...loggedUser } = useAccount();
  const [isHelpUser, setIsHelpUser] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsDemoEnv(window.location.hostname === 'catalogdemo.mycatalog.ai');
      setIsHelpUser(loggedUser.email === 'help@startcatalog.com');
    }
  }, [loggedUser]);

  return { isDemoEnv, isHelpUser };
};
