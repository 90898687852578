'use client';

import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useTenant } from './tenant-context';
import { useGlobalData } from '../data/data';
import { datadogRum } from '@datadog/browser-rum';
import { useAmplitudeContext } from '@/lib/context/analytics-context';
import { LoggedUser } from '@/lib/types/user';

interface IAccountUser extends LoggedUser {
  isDebugModeActive: boolean;
  toggleDebugMode: () => void;
}

const initialData = {
  id: '',
  label: '',
  email: '',
  isDebugModeActive: false,
  toggleDebugMode: () => {},
};

export const AccountContext = createContext<IAccountUser>(initialData);

export const AccountProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loggedUser, setLoggedUser] = useState<LoggedUser>(initialData);
  const [isDebugModeActive, setIsDebugModeActive] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('isDebugModeActive') === 'true';
    }
    return false;
  });

  const router = useRouter();
  const pathName = usePathname();
  const { backendUrl, tenantId } = useTenant();
  const { getLoggedUser } = useGlobalData();
  const { identifyAmplitudeUser } = useAmplitudeContext();
  const protectedPaths = [
    '/',
    '/products',
    '/customers',
    '/reports',
    '/orders',
    '/users',
    '/smartorder',
    '/home',
  ];

  useEffect(() => {
    const manageLogin = async () => {
      try {
        if (isProtectedPath(pathName)) {
          const user = await getLoggedUser();
          if (user) {
            setLoggedUser(user);
            identifyAmplitudeUser?.(user.id);
          } else {
            const currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            router.push(
              `${backendUrl}/admin/auth/auth0?redirectTo=${currentUrl}`,
            );
          }
        }
      } catch (error) {
        console.error('Logged user is incorrect', error);
      }
    };

    manageLogin();
  }, []);

  useEffect(() => {
    if (loggedUser?.id && tenantId) {
      datadogRum.setUser({
        id: loggedUser.id,
        email: loggedUser.email,
        name: loggedUser.label,
        sellerName: tenantId,
      });
    }
  }, [loggedUser, tenantId]);

  const isProtectedPath = (pathname: string) => {
    return protectedPaths.some(
      (protectedPath) =>
        pathname === protectedPath || pathname.startsWith(`${protectedPath}/`),
    );
  };

  const toggleDebugMode = () => {
    if (!loggedUser.email.endsWith('@startcatalog.com')) {
      return;
    }
    const newState = !isDebugModeActive;
    setIsDebugModeActive(newState);
    localStorage.setItem('isDebugModeActive', String(newState));
  };

  const accountUser = useMemo(
    () => ({
      ...loggedUser,
      isDebugModeActive,
      toggleDebugMode,
    }),
    [loggedUser, isDebugModeActive, toggleDebugMode],
  );

  return (
    <AccountContext.Provider value={accountUser}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);

  if (context === null) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
};
