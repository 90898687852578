import { useEffect, createContext, useContext } from 'react';
import { init, setGroup, setUserId, track } from '@amplitude/analytics-browser';
import { useTenant } from './tenant-context';
import { ENVIRONMENT } from '@/lib/config/config';
import { useIsDemoEnv } from '@/lib/hooks/use-is-demo-env';
const AMPLITUDE_API_KEY = '65d5b76603c62c5f46f1abf3fa4b984f';

type AmplitudeContextType = {
  trackAmplitudeEvent?: (
    eventName: string,
    eventProperties?: Record<string, unknown>,
  ) => void;
  identifyAmplitudeUser?: (id: string) => void;
};
export const AmplitudeContext = createContext<AmplitudeContextType | null>(
  null,
);

export const AmplitudeContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useEffect(() => {
    if (
      ENVIRONMENT === 'prod' &&
      typeof window !== 'undefined' &&
      !isHelpUser &&
      !isDemoEnv
    ) {
      init(AMPLITUDE_API_KEY, undefined, {
        defaultTracking: {
          sessions: true,
          pageViews: true,
          formInteractions: true,
          fileDownloads: true,
        },
      });
    }
  }, []);

  const { tenantId } = useTenant();
  const { isHelpUser, isDemoEnv } = useIsDemoEnv();

  const trackAmplitudeEvent = (
    eventName: string,
    eventProperties?: Record<string, unknown>,
  ) => {
    if (
      ENVIRONMENT === 'prod' &&
      typeof window !== 'undefined' &&
      !isHelpUser &&
      !isDemoEnv
    ) {
      track(eventName, eventProperties);
    } else {
      console.log('Amplitude event', eventName, eventProperties);
    }
  };
  const identifyAmplitudeUser = (id: string) => {
    if (ENVIRONMENT === 'prod' && !isHelpUser && !isDemoEnv) {
      setUserId(id);
      setGroup('seller', tenantId);
    }
  };

  const value = { trackAmplitudeEvent, identifyAmplitudeUser };

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export const useAmplitudeContext = () => {
  const context = useContext(AmplitudeContext);
  if (context === null)
    throw new Error(
      'useAmplitudeContext must be used within a AmplitudeContextProvider',
    );
  return context;
};
